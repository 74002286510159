<template>
  <div
    class="
      settings-component
      is-flex is-flex-direction-column
      ion-justify-content-between
      h-100
    "
  >
    <div>
      <h2 class="fw-700 fz-18 subtitle ion-margin-vertical">
        {{ $t('profile.settingsNotifications') }}
      </h2>

      <ion-list class="checkbox-list is-flex is-flex-direction-column mb-5">
        <ion-item class="--item-border-color" >
          <ion-label class="label">
            <p class="fw-700 pb-2">{{ $t('profile.settingsFollowTitle') }}</p>
            <p class="label-text">{{ $t('profile.settingsFollowText') }}</p>
          </ion-label>
          <IonCheckbox
            color="medium"
            @update:modelValue="isFollow = $event"
            :model-value="isFollow"
            class="
              checkbox-app
              --checkbox-border-radius
              --checkbox-background
              --checkbox-border-width
              --checkmark-width
              --checkbox-size
            "
          />
        </ion-item>
        <ion-item class="--item-border-color" >
          <ion-label class="label">
            <p class="fw-700 pb-2">{{ $t('profile.settingsFavoritesTitle') }}</p>
            <p class="label-text">{{ $t('profile.settingsFavoritesText') }}</p>
          </ion-label>
          <IonCheckbox
            color="medium"
            @update:modelValue="isFavorite = $event"
            :model-value="isFavorite"
            class="
              checkbox-app
              --checkbox-border-radius
              --checkbox-background
              --checkbox-border-width
              --checkmark-width
              --checkbox-size
            "
          />
        </ion-item>
      </ion-list>

      <div
        class="
          btns
          is-flex is-flex-direction-column
          ion-align-items-center
          mt-5
          pt-5
        "
      >
        <LanguageButton :select-options="selectOptionsData" />
      </div>
    </div>

    <NotificationsDisabled
      v-if="!pushAvailable && platform !=='web'"
      class="ion-margin-vertical"
    />
  </div>
</template>

<script>
import NotificationsDisabled from '@/components/common/NotificationsDisabled.vue';
import AppSelect from '@/components/common/AppSelect.vue';
import LanguageButton from '@/components/profile/LanguageButton.vue';
import { checkPlatform } from "@/helpers/functions";

import {
  IonButton,
  IonIcon,
  IonLabel,
  IonCheckbox,
  IonItem,
  IonList,
  IonFooter,
  IonToolbar,
} from '@ionic/vue';
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'ProfileSettings',
  components: {
    NotificationsDisabled,
    IonButton,
    IonIcon,
    IonLabel,
    IonCheckbox,
    IonItem,
    IonList,
    IonFooter,
    IonToolbar,
    AppSelect,
    LanguageButton,
  },
  setup() {
    const selectOptionsData = [
      { id: 1, name: 'English', value: 'en' },
      { id: 2, name: 'Dutch', value: 'nl' },
    ];
    const store = useStore();

    const platform = ref(null);
    const getPlatform = async() => platform.value = await checkPlatform();
    getPlatform();

    const pushAvailable = computed(
      () => store.getters['app/notificationsEnabled'],
    );

    const isFollow = computed({
      get() {
        return store.state.profile.notificationsPreferences.notifications_followed_companies;
      },
      set(val) {
        store.dispatch('profile/updateNotificationsPreferences', {
          key: 'notifications_followed_companies',
          value: val ? 1 : 0,
        });
      },
    });

    const isFavorite = computed({
      get() {
        return store.state.profile.notificationsPreferences.notifications_favorite_courses;
      },
      set(val) {
        store.dispatch('profile/updateNotificationsPreferences', {
          key: 'notifications_favorite_courses',
          value: val ? 1 : 0,
        });
      },
    });

    return {
      pushAvailable,
      selectOptionsData,
      isFollow,
      isFavorite,
      platform,
    };
  },
};
</script>

<style lang="scss" scoped>
.btns {
  padding: 0 40px;
}

.title {
  margin: 20px 0;
}

.subtitle {
  padding-left: 20px;
}

.label {
  white-space: normal;
  p {
    color: #ffffff;
  }
  p.label-text {
    font-size: 12px;
  }
}

.checkbox-list > *:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 60px;
  }
}
</style>
