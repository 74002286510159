<template>
  <div v-if="isMounted">
    <ion-datetime-button :datetime="id"/>
    <ion-modal :keep-contents-mounted="true">
      <ion-datetime
        :value="modelValue"
        color="primary"
        :id="id"
        :presentation="presentation"
        @ionChange="onChange"
      />
    </ion-modal>
  </div>
</template>
<script>
import { IonDatetime, IonDatetimeButton, IonModal } from '@ionic/vue';

export default {
  name: "AppDatetimePicker",
  components: { IonDatetime, IonDatetimeButton, IonModal },
  emits: ['ionChange', 'update:modelValue'],
  props: {
    id: {
      type: String,
      default: 'datetime',
    },
    modelValue: {
      type: String,
      default: null,
    },
    presentation: {
      type: String,
      default: 'date',
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    onChange($event) {
      const value = $event.target.value;
      this.$emit('update:modelValue', value);
      this.$emit('ionChange', value);
    },
  },
};
</script>

<style scoped>
ion-datetime-button::part(native) {
  background: var(--ion-background-color);
}

ion-datetime {
  color: black;
}
</style>
